<template>
  <CCard>
    <CCardHeader class="d-flex ">
      <h2 v-if="!id" class="mb-0">New Class</h2>
      <h2 v-else class="mb-0">Edit Class</h2>
    </CCardHeader>
    <CCardBody>
      <form @submit.prevent="submit" autocomplete="off" v-if="(!this.id || !this.loading) && !this.loading_data ">
        <CRow>
          <CCol lg="6">
            <CInput
                label="Title"
                v-model="new_class.name"
                :addInputClasses="{'is-invalid': hasError('name')}"
                :invalid-feedback="getError('name')"
            ></CInput>
            <div class="form-group"
                 :class="{'is-invalid': hasError('instructors')}">
              <label>Instructors</label>
              <multi-select
                  :options="dataForSelect(data.instructors)"
                  :selected="new_class.instructors"
                  :selection="true"
                  :search="true"
                  optionsEmptyPlaceholder="No options"
                  searchPlaceholder="Search"
                  selectionType="tags"
                  @update="updateData($event, 'instructors')"
              ></multi-select>
              <div class="invalid-feedback d-block" v-if="hasError('instructors')">
                {{ getError('instructors') }}
              </div>
            </div>

            <editor
                :api-key="tiny_key"
                v-model="new_class.description"
                :init="{
                   height: 500,
                   menubar: false,
                   plugins: [
                     'advlist autolink lists link image charmap print preview anchor',
                     'searchreplace visualblocks code fullscreen',
                     'insertdatetime media table paste code help wordcount'
                   ],
                   toolbar:
                     'undo redo | formatselect | bold italic backcolor | \
                     alignleft aligncenter alignright alignjustify | \
                     bullist numlist outdent indent | removeformat | help'
                 }"
            />
          </CCol>
          <CCol lg="6">
            <CSelect v-model="new_class.studio" :options="dataForSSelect(data.studios, 'Select Studio')" label="Studio"
                     @update:value="updateData($event, 'studio')"
                     :addInputClasses="{'is-invalid': hasError('studio')}"
                     :invalid-feedback="getError('studio')"></CSelect>

            <div class="form-group"
                 :class="{'is-invalid': hasError('categories')}">
              <label>Categories</label>
              <multi-select
                  :options="dataForSelect(data.categories)"
                  :selected="new_class.categories"
                  :selection="true"
                  optionsEmptyPlaceholder="No options"
                  searchPlaceholder="Search"
                  selectionType="tags"
                  :search="true"
                  @update="updateData($event, 'categories')"
              ></multi-select>
              <div class="invalid-feedback d-block" v-if="hasError('categories')">
                {{ getError('categories') }}
              </div>
            </div>
            <div class="form-group s-select"
                 :class="{'is-invalid': hasError('tags')}">
              <label>Tags</label>
              <multiselect v-model="new_class.tags" :options="dataForTagSelect(data.tags)"
                           placeholder="Search or add a tag" :multiple="true" :taggable="true" @tag="addTag">
                <template slot="option" slot-scope="props">
                  <div data-v-6b8bd229="" tabindex="0" class="c-multi-select-option "
                       :class="{'c-multi-selected':new_class.tags.includes(props.option)}"> {{ props.option && props.option.label ? props.option.label : props.option  }}
                  </div>
                </template>

              </multiselect>
              <div class="invalid-feedback d-block" v-if="hasError('tags')">
                {{ getError('tags') }}
              </div>
            </div>
            <CSelect v-model="new_class.class_type" :options="dataForSSelect(data.class_types, 'Select Class Type')" label="Class Type"
                     @update:value="updateData($event, 'class_type')"
                     disabled
                     :addInputClasses="{'is-invalid': hasError('class_type')}"
                     :invalid-feedback="getError('class_type')"></CSelect>
            <CSelect v-model="new_class.language" :options="dataForSSelect(data.languages, 'Select Language')" label="Language"
                     @update:value="updateData($event, 'language')"
                     :addInputClasses="{'is-invalid': hasError('language')}"
                     :invalid-feedback="getError('language')"></CSelect>
            <CSelect v-model="new_class.length" :options="dataForSSelect(data.length, 'Select Length')" label="Length"
                     @update:value="updateData($event, 'length')"
                     :addInputClasses="{'is-invalid': hasError('length')}"
                     :invalid-feedback="getError('length')"></CSelect>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label>Production Date</label>
                  <vc-date-picker v-model="new_class.production_date" mode="dateTime" is24hr :masks="masks">
                    <template v-slot="{ inputValue, inputEvents }">
                      <input
                          class="form-control "
                          :value="inputValue"
                          v-on="inputEvents"
                      />
                    </template>
                  </vc-date-picker>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>Release Date</label>
                  <vc-date-picker v-model="new_class.release_date" mode="dateTime" is24hr :masks="masks">
                    <template v-slot="{ inputValue, inputEvents }">
                      <input
                          class="form-control "
                          :value="inputValue"
                          v-on="inputEvents"
                      />
                    </template>
                  </vc-date-picker>
                </div>
              </div>
            </div>
            <CInput v-model="new_class.vimeo_id" label="Vimeo ID"
                    disabled
                    :addInputClasses="{'is-invalid': hasError('vimeo_id')}"
                    :invalid-feedback="getError('vimeo_id')"></CInput>
          </CCol>
        </CRow>

        <CButton type="submit" color="primary" class="mt-3">Save</CButton>
      </form>
      <div v-else class="mb-3">
        <CSpinner
            style="width:2rem;height:2rem;"
            color="primary"
            grow
        />
      </div>
    </CCardBody>
  </CCard>
</template>

<script>
import hasApiValidation from "@/mixins/hasApiValidation";
import Editor from '@tinymce/tinymce-vue'
import Multiselect from 'vue-multiselect'
import MultiSelect from "@/components/MultiSelect";

export default {
  name: "NewAdmin",
  mixins: [hasApiValidation],
  props: ['id'],
  components: {
    MultiSelect,
    'editor': Editor,
    Multiselect
  },
  data() {
    return {
      roles: [],
      new_class: {
        name: '',
        instructors: [],
        tags: [],
        categories: [],
        class_type: null,
        length: null,
        language: null,
        production_date: null,
        studio: null,
        release_date: null,
        vimeo_id: '',
      },
      tiny_key: process.env.VUE_APP_TINY_KEY,
      masks: {
        inputDateTime24hr: 'YYYY-MM-DD HH:mm',
      },
      data: {
        instructors: []
      },
      loading_data: true,
      loading: false,
    }
  },
  mounted() {
    if (this.id) {
      this.loading = true;
      this.fetchClass(this.id);
    }
    this.getData();
  },
  methods: {
    fetchClass(id) {
      this.$http.get('/sessions/' + id).then(({data}) => {
        Object.assign(this.new_class, data.data)
      })
          .catch(({response}) => {
            this.$router.push({name: 'ClassList'}).then(()=>{
              if (response && response.data && response.data.message) {
                this.$noty.error(response.data.message)
              }
            })
          }).finally(() => {
        this.loading = false;
      })
    },
    getData() {
      this.loading_data = true;
      this.$http.get('/sessions/data').then(({data}) => {
        this.data = data;
      }).finally(() => {
        this.loading_data = false;
      })
    },
    updateData(data, type) {
      this.new_class[type] = data;
    },
    submit() {
      if (this.id) {
        this.update();
      } else {
        this.save();
      }
    },
    save() {
      this.$http.post('/sessions', this.new_class).then((data) => {
        this.$noty.success('Created');
        this.$router.push({name: 'ClassList'})
      }).catch(({response}) => {
        this.$noty.error(response.data.message);
        this.setErrors(response.data.errors);
      })
    },
    update() {
      this.$http.put('/sessions/' + this.id, this.new_class).then((data) => {
        this.$noty.success('Updated');
        this.$router.push({name: 'ClassList'})
      }).catch(({response}) => {
        this.$noty.error(response.data.message);
        this.setErrors(response.data.errors);
      })
    },
    dataForSelect(data) {
      return data.map(item => {
        return {
          value: item.id,
          // label: item.name,
          text: item.name
        }
      })
    },
    dataForSSelect(data, name = "Select") {
      let arr = [{
        value: null,
        label: name,
        disabled: true,
      }]
      data.forEach(item => {
        arr.push({
          value: item.id,
          label: item.name,
          // text: item.name
        })
      })
      return arr
    },
    dataForTagSelect(data) {
      return data && data.length ? data.map(item => item.name || item) : [];
    },
    addTag(newTagName) {
      this.data.tags.push({id: null, name: newTagName})
      this.new_class.tags.push(newTagName)
    }
  },
  computed: {}
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
.form-group.is-invalid >>> .c-multi-select {
  border-color: var(--danger);
}

div.s-select >>> span.multiselect__option {
  padding: 0;
  background: transparent !important;
  color: #1A1A1C !important;
  font-size: 14px;
}

div.s-select >>> span.multiselect__option:after {
  content: unset !important;
}

div.s-select >>> .multiselect__option--selected {
  font-weight: 400;
}

div.s-select >>> .multiselect__content-wrapper {
  /* position: absolute; */
  top: 100%;
  left: 0;
  /* display: none; */
  float: left;
  width: 100%;
  padding: 0.5rem 0.75rem;
  /* margin-top: 0.625rem; */
  font-size: 0.875rem;
  text-align: left;
  background-clip: padding-box;
  border-radius: 0.25rem;
  color: #1A1A1C;
  background-color: #fff;
  border-color: #d8dbe0;
  border: 0;
  box-shadow: 0 4px 5px 0 rgba(var(--elevation-base-color), .14), 0 1px 10px 0 rgba(var(--elevation-base-color), .12), 0 2px 4px -1px rgba(var(--elevation-base-color), .20);
}

div.s-select >>> .c-multi-select-option::after, div.s-select >>> .c-multi-select-option::before {
  top: 0.5rem;
}

div.s-select >>> .multiselect__tag {
  text-overflow: ellipsis;
  display: inline-block;
  height: 25px;
  margin: 2px;
  border: 1px solid;
  border-radius: 0.25rem;
  background-color: #ebedef;
  border-color: #d8dbe0;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  color: #768192;
}
</style>