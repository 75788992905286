<template>
  <CModal
      :show.sync="showModal"
      :size="'sm'"
      centered
      @update:show="closeModal"
  >
    <template #header-wrapper>
      <CCardHeader>
        <h4 class="font-weight-bold  px-3">
          New Category
        </h4>
      </CCardHeader>
    </template>

    <CCardBody class="py-0 ">
      <CInput v-model="form.name"
              label="Name"
              :addInputClasses="{'is-invalid': hasError('name')}"
              :invalid-feedback="getError('name')"></CInput>
      <div class="d-flex mt-3 align-items-center justify-content-between">
        <CButton color="secondary" variant="outline" class=" text-uppercase"
                 @click="showModal = false"
        >
          Cancel
        </CButton>
        <CButton color="primary" class=" text-uppercase"
                 @click="submit()"
        >
          Save
        </CButton>
      </div>
    </CCardBody>
    <template #footer-wrapper><span> </span></template>
  </CModal>
</template>

<script>
import hasApiValidation from "@/mixins/hasApiValidation";

export default {
  name: "AddCategoryModal",
  mixins: [hasApiValidation],
  data() {
    return {
      showModal: false,
      step: 1,
      finished: false,
      form: {
        name: ''
      },
      item: null
    }
  },
  methods: {
    open(category = null) {
      this.showModal = true;
      if (category) {
        this.item = category;
        this.form.name = category.name;
      }
    },
    submit() {
      if (!!this.item) {
        this.update();
      } else {
        this.save();
      }
    },
    update() {
      this.setErrors({});
      this.$http.put('/help-categories/'+this.item.id, this.form)
          .then(() => {
            this.showModal = false;
            this.$emit('updated');
            this.form = {
              name: '',
            }
            this.item = null;
          })
          .catch(({response}) => {
            this.setErrors(response.data.errors);
            this.$noty.error(response.data.message);
          })
    },
    save() {
      this.setErrors({});
      this.$http.post('/help-categories', this.form)
          .then(() => {
            this.showModal = false;
            this.$emit('created');
            this.form = {
              name: '',
            }
            this.item = null;
          })
          .catch(({response}) => {
            this.setErrors(response.data.errors);
            this.$noty.error(response.data.message);
          })
    },
    closeModal() {
      this.setErrors({});
      this.item = null;
      this.form = {
        name: '',
      }
      this.$emit('modal:close');
    }
  }
}
</script>

<style scoped>

</style>