<template>
  <div>
    <nav class="nav nav-pills mb-3">
    <router-link :to="{name:'ClassList'}" class="mr-3 nav-link" v-if="$auth.check('classes_list_page_tab')">Classes</router-link>
    <router-link :to="{name:'ClassCategories'}" class="mr-3 nav-link"  v-if="$auth.check('classes_categories')">Home Category Order</router-link>
<!--    <router-link :to="{name:'ClassCalendar'}" class="mr-3"  v-if="$auth.check('classes_calendar')">Calendar</router-link>-->
    <router-link :to="{name:'HelpVideo'}" class="nav-link mr-3"  v-if="$auth.check('help_video_page_tab')">Help Videos</router-link>
    <router-link :to="{name:'Studios'}" class="nav-link"  v-if="$auth.check('help_video_page_tab')">Studios</router-link>

    </nav>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "Classes",
  created() {
    if (this.$route.name === 'ClassList' && !this.$auth.check('classes_list_page_tab')) {
      this.$router.push({name: 'ClassCategories'});
      return;
    }
    if (this.$route.name === 'ClassCategories' && !this.$auth.check('classes_categories')) {
      this.$router.push({name: 'ClassCalendar'});
      return;
    }
    if (this.$route.name === 'ClassCalendar' && !this.$auth.check('classes_calendar')) {
      this.$router.push({name: 'HelpVideo'});
      return;
    }
    if (this.$route.name === 'HelpVideo' && !this.$auth.check('help_video_page_tab')) {
      this.$router.push({name: 'ClassList'});
      return;
    }
  }
}
</script>

<style scoped>

</style>