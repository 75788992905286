<template>
  <CModal
      :show.sync="showModal"
      centered
      @update:show="closeModal"
      title="Swap Type"
  >
    <h3 class="">Are you sure you want to change subscription type to {{ statuses[type] }}
    </h3>
    <CCardBody class="pt-0">
      <div class="d-flex mt-3 align-items-center justify-content-center">
        <CButton color="secondary" class="mx-auto text-uppercase"
                 @click="showModal = false"
        >
          Cancel
        </CButton>
        <CButton color="primary" class="mx-auto text-uppercase btn_yes"
                 @click="save()"
        >
          Yes
        </CButton>
      </div>
    </CCardBody>
    <template #footer-wrapper>
      <div class="d-flex my-0 align-items-center justify-content-center">
      </div>
    </template>

  </CModal>
</template>

<script>
export default {
  name: "AdminSubscriptionModal",
  data() {
    return {
      user: null,
      type: null,
      showModal: false,
      loading: true,
      statuses: {
        0: 'None',
        1: 'Basic',
        2: 'Full',
      }
    }
  },
  methods: {
    open(user, type) {
      this.showModal = true;
      this.user = Object.assign({}, user);
      this.type = type;
    },
    closeModal() {
      this.$emit('modal:close');
      document.querySelector('body').classList.remove('modal_open')
    },
    save() {
      this.axios.put('/users/' + this.user.id + '/admin_subscription', {type: this.type})
          .then(() => {
            this.$noty.success('Updated!');
          })
          .catch(() => {
            this.$noty.error('Oops, something went wrong!');
          })
          .finally(() => {
            this.showModal = false;
            this.closeModal();
          })
    }
  },
  computed: {}
}
</script>

<style scoped>
</style>