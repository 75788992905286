var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CCard',[_c('CCardHeader',{staticClass:"d-flex justify-content-end"},[_c('h2',{staticClass:"text-uppercase font-weight-bold mb-0"},[_vm._v("List of Classes")]),(_vm.$auth.check('classes_crud'))?_c('CButton',{staticClass:"ml-auto mr-3",attrs:{"color":"primary"},on:{"click":_vm.createClass}},[_vm._v("Add New")]):_vm._e(),_c('CButton',{staticClass:"mr-3",attrs:{"color":"primary"},on:{"click":_vm.openFilters}},[_vm._v("Filters")]),_c('CInput',{staticClass:"mb-0",attrs:{"placeholder":"Search"},on:{"update:value":_vm.fetchData},model:{value:(_vm.filters.search),callback:function ($$v) {_vm.$set(_vm.filters, "search", $$v)},expression:"filters.search"}})],1),_c('Transition',[(_vm.activeFiltersList.length > 0)?_c('CCardHeader',{staticClass:"active-filters-options"},_vm._l((_vm.activeFiltersList),function(filter){return _c('div',{key:filter.title},[_c('strong',{staticClass:"mr-2"},[_vm._v(_vm._s(filter.title)+":")]),_vm._l((filter.options),function(option){return _c('span',{key:("" + (filter.slug) + (option.title)),staticClass:"badge bg-info text-white mr-2 h6 c-pointer",on:{"click":function($event){return _vm.removeActiveFilter(filter.slug, option.value)}}},[_vm._v(" "+_vm._s(option.title)+" "),_c('i',{staticClass:"fas fa-times"})])})],2)}),0):_vm._e()],1),_c('CCardBody',[_c('CDataTable',{staticClass:"mb-0",attrs:{"items":_vm.data,"fields":_vm.c_fields,"pagination":false,"sorter":{external: true},"loading":_vm.loading},on:{"update:sorter-value":_vm.sortingChange},scopedSlots:_vm._u([{key:"name",fn:function(data){return [_c('td',[_c('span',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
              content: _vm.statuses[data.item.status],
              placement: 'left',
              appendToBody: true,
          }),expression:"{\n              content: statuses[data.item.status],\n              placement: 'left',\n              appendToBody: true,\n          }"}]},[_c('span',{staticClass:"status-badge mr-1",class:{
              'bg-success': data.item.status === 'released',
              'bg-secondary': data.item.status === 'draft',
              'bg-dark': data.item.status === 'ready',
              'bg-info': data.item.status === 'filmed',
          }}),_vm._v(_vm._s(data.item.name)+" ")])])]}},{key:"categories",fn:function(data){return [_c('td',_vm._l((data.item.categories),function(cat){return _c('span',{staticClass:"badge bg-info text-white mr-1"},[_vm._v(_vm._s(cat))])}),0)]}},{key:"instructors",fn:function(data){return [_c('td',[_vm._v(" "+_vm._s(data.item.instructors.map(function (i) { return i.name; }).join(', '))+" ")])]}},{key:"actions",fn:function(data){return [_c('td',[_c('div',{staticClass:"d-flex flex-nowrap"},[(!data.item.release_date)?_c('CButton',{staticClass:"text-white mr-2",attrs:{"size":"sm","color":"success"},on:{"click":function($event){return _vm.releaseClass(data.item)}}},[_vm._v("Publish")]):_vm._e(),_c('CButton',{staticClass:"text-white mr-2",attrs:{"size":"sm","color":"warning"},on:{"click":function($event){return _vm.editClass(data.item.id)}}},[_vm._v("Edit")]),_c('CButton',{staticClass:"text-white",attrs:{"size":"sm","color":"danger"},on:{"click":function($event){return _vm.deleteClass(data.item.id)}}},[_vm._v("Delete")])],1)])]}}])}),_c('pagination',{staticClass:"mr-3",attrs:{"current-page":_vm.options.page,"last-page":_vm.options.last_page,"loading":_vm.loading},on:{"paginate":_vm.pageChange}})],1),_c('filters-modal',{ref:"filters_modal",on:{"apply":_vm.changeFilters}}),_c('delete-confirm-modal',{ref:"delete_modal",on:{"confirmed":_vm.confirmDelete}}),_c('release-modal',{ref:"release_modal",on:{"confirmed":_vm.confirmRelease}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }