<template>
  <CCard>
    <CCardHeader class="d-flex justify-content-end">
      <h2 class="text-uppercase font-weight-bold mb-0">List of Classes</h2>
      <CButton @click="createClass" class="ml-auto mr-3" color="primary" v-if="$auth.check('classes_crud')">Add New</CButton>

      <CButton @click="openFilters" class="mr-3" color="primary">Filters</CButton>
      <CInput v-model="filters.search" @update:value="fetchData" class="mb-0" placeholder="Search"></CInput>
    </CCardHeader>
    <Transition>
      <CCardHeader v-if="activeFiltersList.length > 0" class="active-filters-options">
        <div v-for="filter in activeFiltersList"
             :key="filter.title">
          <strong class="mr-2">{{ filter.title }}:</strong>
          <span
            v-for="option in filter.options"
            :key="`${filter.slug}${option.title}`"
            class="badge bg-info text-white mr-2 h6 c-pointer" @click="removeActiveFilter(filter.slug, option.value)">
        {{ option.title }} <i class="fas fa-times"></i>
      </span>
        </div>
      </CCardHeader>
    </Transition>

    <CCardBody>
      <CDataTable
          :items="data"
          :fields="c_fields"
          :pagination="false"
          :sorter="{external: true}"
          :loading="loading"
          class="mb-0"
          @update:sorter-value="sortingChange"
      >
        <template #name="data">
          <td>
            <span v-c-tooltip="{
                content: statuses[data.item.status],
                placement: 'left',
                appendToBody: true,
            }">
            <span class="status-badge mr-1" :class="{
                'bg-success': data.item.status === 'released',
                'bg-secondary': data.item.status === 'draft',
                'bg-dark': data.item.status === 'ready',
                'bg-info': data.item.status === 'filmed',
            }" ></span>{{ data.item.name }}
            </span>
          </td>
        </template>
        <template #categories="data">
          <td>
            <span class="badge bg-info text-white mr-1" v-for="cat in data.item.categories">{{ cat }}</span>
          </td>
        </template>
        <template #instructors="data">
          <td>
            {{data.item.instructors.map(i => i.name).join(', ')}}
          </td>
        </template>
        <template #actions="data">
          <td>
            <div class="d-flex flex-nowrap">
              <CButton size="sm" color="success" class="text-white mr-2" @click="releaseClass(data.item)" v-if="!data.item.release_date">Publish</CButton>
              <CButton size="sm" color="warning" class="text-white mr-2" @click="editClass(data.item.id)">Edit</CButton>
              <CButton size="sm" color="danger" class="text-white" @click="deleteClass(data.item.id)">Delete</CButton>
            </div>
          </td>
        </template>
      </CDataTable>
      <pagination :current-page="options.page" @paginate="pageChange" :last-page="options.last_page" class="mr-3" :loading="loading"/>
    </CCardBody>
    <filters-modal ref="filters_modal" @apply="changeFilters"></filters-modal>
    <delete-confirm-modal ref="delete_modal" @confirmed="confirmDelete"></delete-confirm-modal>
    <release-modal ref="release_modal" @confirmed="confirmRelease"></release-modal>
  </CCard>
</template>

<script>
import externalTable from "@/mixins/externalTable";
import FiltersModal from "@/views/sessions/FiltersModal";
import DeleteConfirmModal from "@/views/sessions/DeleteConfirmModal";
import ReleaseModal from "@/views/sessions/ReleaseModal";

export default {
  name: "Sessions",
  components: {ReleaseModal, DeleteConfirmModal, FiltersModal},
  mixins: [externalTable],
  data() {
    return {
      filters: {
        search: '',
        instructor: [],
        class_type: [],
        language: [],
        tag: [],
        category: [],
        status: null,
      },
      statuses: {
        released: 'Released',
        draft: 'Draft',
        ready: 'Ready',
        filmed: 'Filmed',
      },
      fields: [
        {
          key: 'name', label: 'Name',
        },
        {
          key: 'categories', label: 'Categories', sorter:false
        },
        {
          key: 'instructors', label: 'Instructors', sorter:false
        },
        {
          key: 'length', label: 'Length'
        },
        {
          key: 'class_type', label: 'Type'
        },
        {
          key: 'language', label: 'Language'
        },
        {
          key: 'production_date', label: 'Production Date'
        },
        {
          key: 'release_date', label: 'Release Date'
        },
      ],
      url: '/sessions'
    }
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    openFilters() {
      this.$refs.filters_modal.open(this.filters);
    },
    changeFilters(data) {
      this.options.page = 1;
      this.filters = {...this.filters, ...data};
      this.fetchData();
    },
    createClass() {
      this.$router.push({name: 'New Class'});
    },
    editClass(id) {
      this.$router.push({name: 'Edit Class', params: {id: id}});
    },
    releaseClass(item){
      this.$refs.release_modal.open(item);
    },
    deleteClass(id) {
      this.$refs.delete_modal.open(id);
    },
    confirmDelete(id) {
      this.$http.delete('/sessions/' + id + '').then(() => {
        this.$noty.success('Deleted!');
        this.fetchData()
      })
    },
    confirmRelease() {
      this.fetchData()
    },
    removeActiveFilter(filterFieldName, optionValue){
        this.$set(
          this.filters,
          filterFieldName,
          Array.isArray(this.filters[filterFieldName])
            ? this.filters[filterFieldName].filter(value => value !== optionValue)
            : null
        );
      this.fetchData();
    }
  },
  computed: {
    filtersData(){
      return this.$store.state.classesFilterData
    },
    c_fields() {
      let fields = this.fields.slice(0);
      // if(this.$auth.check('sessions_crud')){
      if (true) {
        fields.push(
            {
              key: 'actions', label: 'Actions',
            });
      }
      return fields
    },
    activeFiltersList(){
      let filters = [];
      let getFilterOptionNameById = (optionName, id) =>{
        let option = this.filtersData[optionName].find(o => o.id === id)
        return option ? option.name : '';
      }

      //prepare status
      if (this.filters.status){
        filters.push({title: 'Status', slug: 'status', options: [{title: this.statuses[this.filters.status], value: this.filters.status}]})
      }
      //prepare other options
      [
        { title: 'Instructors', slug: 'instructor', filterField: 'instructors' },
        { title: 'Languages', slug: 'language', filterField: 'languages' },
        { title: 'Class types', slug: 'class_type', filterField: 'class_types' },
        { title: 'Categories', slug: 'category', filterField: 'categories' },
        { title: 'Tags', slug: 'tag', filterField: 'tags' },
      ].forEach(( {title, slug, filterField} ) => {
        if (this.filters[slug].length){
          filters.push(
            {
              title,
              slug,
              options: this.filters[slug].map(valueId => ({value: valueId, title: getFilterOptionNameById(filterField, valueId)}) ),
            }
          )
        }
      });

      return filters;
    }
  }
}
</script>

<style scoped lang="scss">
  .active-filters-options{
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    & > div {
      line-height: 2.2rem;
      margin-right: .7rem;
    }
  }
</style>