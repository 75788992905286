<template>
  <CCard>
    <CCardHeader class="d-flex ">
      <h2 v-if="!id" class="mb-0">New Category</h2>
      <h2 v-else class="mb-0">Edit Category</h2>
    </CCardHeader>
    <CCardBody>
      <form @submit.prevent="submit" autocomplete="off" v-if="(!this.id || !this.loading)">
        <CInput
            label="Name"
            v-model="new_category.name"
            :addInputClasses="{'is-invalid': hasError('name')}"
            :invalid-feedback="getError('name')"
        ></CInput>
        <CButton type="submit" color="primary" class="mt-3">Save</CButton>
      </form>
      <div v-else class="mb-3">
        <CSpinner
            style="width:2rem;height:2rem;"
            color="primary"
            grow
        />
      </div>
    </CCardBody>
  </CCard>
</template>

<script>
import hasApiValidation from "@/mixins/hasApiValidation";

export default {
  name: "NewCategory",
  mixins: [hasApiValidation],
  props: ['id'],
  data() {
    return {
      roles: [],
      new_category: {
        name: '',
      },
      loading_data: true,
      loading: false,
    }
  },
  mounted() {
    if (this.id) {
      this.loading = true;
      this.fetchClass(this.id);
    }
  },
  methods: {
    fetchClass(id) {
      this.$http.get('/categories/' + id).then(({data}) => {
        Object.assign(this.new_category, data.data)
      }).catch(()=>{
        this.$router.push({name:"ClassCategories"}).then(()=>{
          this.$noty.error('Not Found')
        })
      }).finally(() => {
        this.loading = false;
      })
    },
    submit() {
      if (this.id) {
        this.update();
      } else {
        this.save();
      }
    },
    save() {
      this.$http.post('/categories/', this.new_category).then((data) => {
        this.$noty.success('Created');
        this.$router.push({name: 'ClassCategories'})
      }).catch(({response}) => {
        this.$noty.error(response.data.message);
        this.setErrors(response.data.errors);
      })
    },
    update() {
      this.$http.put('/categories/' + this.id, this.new_category).then((data) => {
        this.$noty.success('Updated');
        this.$router.push({name: 'ClassCategories'})
      }).catch(({response}) => {
        if(response.status !== 404){
          this.$noty.error(response.data.message);
          this.setErrors(response.data.errors);
        } else {
          this.$router.push({name:"ClassCategories"}).then(()=>{
            this.$noty.error('Not Found')
          })
        }
      })
    },
  },
  computed: {}
}
</script>
