<template>
  <CCard>
    <CCardHeader class="d-flex justify-content-between align-items-center ">
      <h2 v-if="!id" class="mb-0">New Instructor</h2>
      <h2 v-else class="mb-0">Edit Instructor</h2>
      <CButton @click="resetPassword" color="primary">Reset Password</CButton>
    </CCardHeader>
    <CCardBody>
      <form @submit.prevent="submit" autocomplete="off" v-if="!this.id || !this.user_loading">
        <CInput
            label="First Name"
            v-model="new_instructor.first_name"
            autocomplete="first-name"
            :addInputClasses="{'is-invalid': hasError('first_name')}"
            :invalid-feedback="getError('first_name')"
        ></CInput>
        <CInput
            label="Last Name"
            v-model="new_instructor.last_name"
            autocomplete="last-name"
            :addInputClasses="{'is-invalid': hasError('last_name')}"
            :invalid-feedback="getError('last_name')"
        ></CInput>
        <CInput
            label="Email"
            v-model="new_instructor.email"
            autocomplete="email"
            :addInputClasses="{'is-invalid': hasError('email')}"
            :invalid-feedback="getError('email')"
        ></CInput>
        <div class="mb-3">
          <label>Phone</label>
          <vue-tel-input v-model="new_instructor.phone" mode="international"></vue-tel-input>
          <div class="invalid-feedback d-block" v-if="hasError('phone')">
            {{ getError('phone') }}
          </div>
        </div>
        <CTextarea
            label="Instructor Biography"
            v-model="new_instructor.description"
            autocomplete="description"
            :addInputClasses="{'is-invalid': hasError('description')}"
            :invalid-feedback="getError('description')"
        ></CTextarea>
        <CButton type="submit" color="primary">Save</CButton>
      </form>
      <div v-else class="mb-3">
        <CSpinner
            style="width:2rem;height:2rem;"
            color="primary"
            grow
        />
      </div>
    </CCardBody>
  </CCard>
</template>

<script>
import hasApiValidation from "@/mixins/hasApiValidation";

export default {
  name: "EditInstructor",
  mixins: [hasApiValidation],
  props: ['id'],
  data() {
    return {
      roles: [],
      new_instructor: {
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        description: '',
      },
      loading_roles: true,
      user_loading: false,
    }
  },
  mounted() {
    this.user_loading = true;
    this.fetchUser(this.id);
  },
  methods: {
    fetchUser(id) {
      this.$http.get('/instructors/' + id).then(({data}) => {
        this.new_instructor.id = data.data.id
        this.new_instructor.first_name = data.data.first_name;
        this.new_instructor.last_name = data.data.last_name;
        this.new_instructor.email = data.data.email;
        this.new_instructor.phone = data.data.phone;
        this.new_instructor.description = data.data.description;
      }).finally(() => {
        this.user_loading = false;
      })
    },
    updateData(data) {
      this.new_instructor.roles = data;
    },
    logPhone(e) {
      console.log('loeg', e)
    },
    updatePhone(e) {
      console.log(e)
    },
    submit() {
      this.update();
    },
    resetPassword() {
      this.$http.put('/instructors/' + this.id + '/password').then((data) => {
        this.$noty.success('New password has been sent to the user\'s email');
      }).catch(({response}) => {
        this.$noty.error(response.data.message);
      })
    },
    update() {
      this.setErrors({});
      this.$http.put('/instructors/' + this.id, this.new_instructor).then((data) => {
        this.$router.push({name: 'Instructors'}).then(() => {
          this.$noty.success('Updated');
        })
      }).catch(({response}) => {
        this.$noty.error(response.data.message);
        this.setErrors(response.data.errors);
      })
    }
  },
}
</script>

<style scoped>

</style>