<template>
  <CCard>
    <CCardHeader class="d-flex justify-content-end align-items-center">
      <h2 class="text-uppercase font-weight-bold mr-auto">Enterprise Users</h2>
      <CInput v-model="filters.search" @update:value="searchChange" class="mb-0" placeholder="Search"></CInput>
    </CCardHeader>
    <CCardBody>
      <CDataTable
          :items="data"
          :fields="c_fields"
          :pagination="false"
          :sorter="{external: true}"
          :loading="loading"
          class="mb-0"
          @update:sorter-value="sortingChange"
      >
        <template #actions="data">
          <td>
            <div class="d-flex">
              <CButton size="sm" color="warning" class="text-white mr-2" @click="editUser(data.item.id)">Edit</CButton>
              <CButton size="sm" color="danger" class="text-white mr-2" @click="blockUser(data.item.id)"
                       >Block
              </CButton>
              <CDropdown
                  color="info"
                  size="sm"
                  toggler-text=""
              >
                <template #toggler-content><span >Actions</span>
                </template>
                <CDropdownItem @click="resendVerification(data.item)"
                               v-if="data.item.email && !data.item.email_verified_at">Resend
                  Verification
                </CDropdownItem>
                <CDropdownItem @click="resetPassword(data.item)" v-if="data.item.email && data.item.email_verified_at">
                  Reset Password
                </CDropdownItem>
                <CDropdownItem @click="verify(data.item)" v-if="!data.item.email_verified_at">Verify</CDropdownItem>
              </CDropdown>
            </div>
          </td>
        </template>
        <template #active_subscriptions_count="{item}">
          <td>
            {{item.active_subscriptions_count || 0}}
          </td>
        </template>
        <template #active_tablets_count="{item}">
          <td>
            {{item.active_tablets_count || 0}}
          </td>
        </template>
      </CDataTable>
      <pagination :current-page="options.page" @paginate="pageChange" :last-page="options.last_page" class="mr-3"/>
    </CCardBody>
    <delete-confirm-modal ref="block_modal" @confirmed="confirmBlockUser"></delete-confirm-modal>
    <delete-confirm-modal ref="delete_modal" @confirmed="confirmDeleteUser"></delete-confirm-modal>
  </CCard>
</template>

<script>
import externalTable from "@/mixins/externalTable";
import DeleteConfirmModal from "./DeleteConfirmModal";

export default {
  name: "EnterpriseUserList",
  components: {DeleteConfirmModal},
  mixins: [externalTable],
  data() {
    return {
      subscriptions: false,
      filters: {
        search: '',
        active: [],
      },
      statuses: [
        // {value: null, label: 'All'},
        {value: 1, label: 'Active'},
        {value: 0, label: 'Blocked'},
      ],
      fields: [
        {
          key: 'active', label: 'Status',
        },
      ],
      url: '/enterprise-users',
      loadingActionUser: null,
    }
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    createUser() {
      this.$router.push({name: 'New Enterprise User'});
    },
    editUser(id) {
      this.$router.push({name: 'Edit Enterprise User', params: {id: id}});
    },
    blockUser(id) {
      this.$refs.block_modal.open(id);
    },
    confirmBlockUser(id) {
      this.$http.put('/enterprise-users/' + id + '/block').then(() => {
        this.$noty.success('Blocked!');
        this.fetchData()
      })
    },
    unblockUser(id) {
      this.$http.put('/enterprise-users/' + id + '/unblock').then(() => {
        this.$noty.success('Unblocked!');
        this.fetchData()
      })
    },
    confirmDeleteUser(id) {
      this.$http.delete('/enterprise-users/' + id).then(() => {
        this.$noty.success('Deleted!');
        this.fetchData()
      })
    },
    resendVerification(user) {
      this.loadingActionUser = user.id;
      this.$http.put('/enterprise-users/' + user.id + '/resend-verification').then(() => {
        this.$noty.success('Email sent!');
      })
          .catch(({response}) => {
            this.$noty.error(response.data.message)
          })
          .finally(() => {
            this.fetchData()
            this.loadingActionUser = null;
          })
    },
    resetPassword(user) {
      this.loadingActionUser = user.id;
      this.$http.put('/enterprise-users/' + user.id + '/reset-password').then(() => {
        this.$noty.success('Email sent!');
      })
          .catch(({response}) => {
            this.$noty.error(response.data.message)
          })
          .finally(() => {
            this.fetchData()
            this.loadingActionUser = null;
          })
    },
    verify(user) {
      this.loadingActionUser = user.id;
      this.$http.put('/enterprise-users/' + user.id + '/verify').then(() => {
        this.$noty.success('Email sent!');
      })
          .catch(({response}) => {
            this.$noty.error(response.data.message)
          })
          .finally(() => {
            this.fetchData()
            this.loadingActionUser = null;
          })

    },
  },
  computed: {
    c_fields() {
      let fields = [
        {
          key: 'name', label: 'Name',
        },
        {
          key: 'email', label: 'Email',
        },
        {
          key: 'active_subscriptions_count', label: 'Subscriptions',
        },
        {
          key: 'active_tablets_count', label: 'Active',
        },
      ];
      fields.push(
          {
            key: 'actions', label: 'Actions',
          }
      );
      return fields
    }
  }
}
</script>

<style scoped>
.fa-google {
  background: conic-gradient(from -45deg, #ea4335 110deg, #4285f4 90deg 180deg, #34a853 180deg 270deg, #fbbc05 270deg) 73% 55%/150% 150% no-repeat;
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  -webkit-text-fill-color: transparent;
}
</style>