<template>
  <div class="c-app flex-row align-items-center ">
    <CContainer>
      <CRow class="justify-content-center">
        <CCol md="8" lg="5">
          <CCard class="mx-4 mb-0">
            <CCardBody class="p-4 px-5">
              <CForm class="d-flex flex-column">
                <p class="text-center font-weight-bold mb-4 text-uppercase">Forgot Password?</p>
                <CInput
                    :placeholder="'Password'"
                    type="password"
                    autocomplete="new-password"
                    v-model="formData.password"
                    :addInputClasses="{'is-invalid': hasError('password', 'formData')}"
                    :invalid-feedback="getError('password', 'formData')"
                >
                  <template #append-content>
                    <CIcon name="cil-lock-locked"/>
                  </template>
                </CInput>
                <CInput
                    :placeholder="'Confirm Password'"
                    type="password"
                    autocomplete="new-password"
                    class="mb-4"
                    v-model="formData.password_confirmation"
                    :addInputClasses="{'is-invalid': hasError('password_confirmation', 'formData')}"
                    :invalid-feedback="getError('password_confirmation', 'formData')"
                >
                  <template #append-content>
                    <CIcon name="cil-lock-locked"/>
                  </template>
                </CInput>
                <p class="text-danger" v-if="hasError('email')">{{getError('email')}}</p>
                <CButton color="primary" class="px-5 mb-3 align-self-center text-uppercase" @click="save">Send</CButton>
              </CForm>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>
  import hasApiValidation from "@/mixins/hasApiValidation";
  import { maxLength, minLength, required, sameAs} from "vuelidate/lib/validators";

  export default {
    name: 'ResetPassword',
    mixins: [hasApiValidation],
    data() {
      return {
        formData: {
          password: '',
          password_confirmation: '',
        }
      }
    },
    validations: {
      formData: {
        password: {
          required: required,
          minLength: minLength(8),
          maxLength: maxLength(32),
        },
        password_confirmation: {
          required: required,
          sameAs: sameAs('password'),
          minLength: minLength(8),
          maxLength: maxLength(32),
        }
      }
    },
    mounted() {
      Object.assign(this.formData, this.$route.query);
    },
    methods: {
      save() {
        this.$http.post('/auth/password/reset', this.formData)
            .then(({data}) => {
              this.$noty.success(data.message);
              this.$router.push('login');
            })
            .catch(({response}) => {
              if (response && response.data.message) {
                this.$noty.error(response.data.message);
              }
              this.setErrors(response.data.errors);
            });
      },
      validate() {
        return !this.$v.formData.$anyError;
      },
    },
    watch: {
      'formData.password': function () {
        this.clearError('password');
        this.$v.formData.password.$touch();
      },
      'formData.password_confirmation': function () {
        this.$v.formData.password_confirmation.$touch();
      },
    }
  }
</script>
