<template>
  <CCard>
    <CCardHeader class="d-flex ">
      <h2 v-if="!id" class="mb-0">New User</h2>
      <h2 v-else class="mb-0">Edit User</h2>
    </CCardHeader>
    <CCardBody>
      <form @submit.prevent="submit" autocomplete="off" v-if="!this.id || !this.user_loading">
        <CRow>
          <CCol md="6" lg="4">
            <CInput
                label="Name"
                v-model="new_user.name"
                autocomplete="name"
                :addInputClasses="{'is-invalid': hasError('name')}"
                :invalid-feedback="getError('name')"
            ></CInput>
          </CCol>
          <CCol md="6" lg="4">
            <CInput
                label="Email"
                v-model="new_user.email"
                autocomplete="email"
                :addInputClasses="{'is-invalid': hasError('email')}"
                :invalid-feedback="getError('email')"
            ></CInput>
          </CCol>
          <CCol md="6" lg="4">
            <CInput
                label="Address"
                v-model="new_user.address"
                :addInputClasses="{'is-invalid': hasError('address')}"
                :invalid-feedback="getError('address')"
            ></CInput>
          </CCol>
          <CCol md="6" lg="4">
            <CInput
                label="City"
                v-model="new_user.city"
                :addInputClasses="{'is-invalid': hasError('city')}"
                :invalid-feedback="getError('city')"
            ></CInput>
          </CCol>
          <CCol md="6" lg="4">
            <CInput
                label="Zip Code"
                v-model="new_user.zip"
                :addInputClasses="{'is-invalid': hasError('zip')}"
                :invalid-feedback="getError('zip')"
            ></CInput>
          </CCol>
          <CCol md="6" lg="4">
            <CInput
                label="Phone"
                v-model="new_user.phone"
                :addInputClasses="{'is-invalid': hasError('phone')}"
                :invalid-feedback="getError('phone')"
            ></CInput>
          </CCol>
        </CRow>
        <CButton type="submit" color="primary">Save</CButton>
      </form>
      <div v-else class="mb-3">
        <CSpinner
            style="width:2rem;height:2rem;"
            color="primary"
            grow
        />
      </div>
    </CCardBody>
  </CCard>
</template>

<script>
import hasApiValidation from "@/mixins/hasApiValidation";

export default {
  name: "EditEnterpriseUser",
  mixins: [hasApiValidation],
  props: ['id'],
  data() {
    return {
      roles: [],
      new_user: {
        name: '',
        email: '',
        address: '',
        phone: '',
        zip: '',
        city: '',
      },
      loading_roles: true,
      user_loading: false,
    }
  },
  mounted() {
    this.user_loading = true;
    this.fetchUser(this.id);
  },
  methods: {
    fetchUser(id) {
      this.$http.get('/enterprise-users/' + id).then(({data}) => {
        this.new_user.id = data.data.id
        this.new_user.name = data.data.name;
        this.new_user.email = data.data.email;
        this.new_user.address = data.data.address;
        this.new_user.phone = data.data.phone;
        this.new_user.zip = data.data.zip;
        this.new_user.city = data.data.city;
      }).finally(() => {
        this.user_loading = false;
      })
    },
    submit() {
      this.update();
    },
    update() {
      this.$http.put('/enterprise-users/' + this.id, this.new_user).then((data) => {
        this.$noty.success('Updated');
        this.$router.push({name: 'Enterprise Users'})
      }).catch(({response}) => {
        this.$noty.error(response.data.message);
        this.setErrors(response.data.errors);
      })
    }
  },
}
</script>

<style scoped>

</style>